import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    "Deep Learning",
    "Computer Vision",
    "TensorFlow",
    "PyTorch",
    "Python",
    "Machine Learning",
    "Object Detection",
    "Facial Recognition",
    "YOLO",
    "OpenCV",
    "Keras",
    "Edge Computing",
    "Docker",
    "Kubernetes",
    "AWS",
    "CI/CD",
    "Git",
    "Django",
    "FastAPI",
    "RESTful APIs",
    "JavaScript",
    "React",
    "SQL",
    "Data Analysis",
    "Streamlit"
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! I'm Nouman Rasheed, a passionate Deep Learning and Computer Vision Engineer dedicated to pushing the boundaries of artificial intelligence. 
              With a strong foundation in computer science and a keen interest in cutting-edge technologies, I specialize in developing innovative AI solutions 
              that address real-world challenges.
            </p>
            <p>
              My expertise lies in creating and optimizing machine learning models, particularly in the realm of computer vision. I'm driven by the potential of 
              AI to transform industries and improve everyday life. Whether it's enhancing safety systems, streamlining business processes, or creating 
              intelligent applications, I'm always excited to explore new ways to apply AI technologies.
            </p>

            <p>
              Currently, I'm leveraging my skills at{' '}
              <a href="https://deeritc.com/" target="_blank" rel="noreferrer">
                DeerItc
              </a>
              , where I continue to grow and contribute to groundbreaking AI initiatives. I'm passionate about staying at the forefront of technological 
              advancements and enjoy the challenge of turning complex problems into elegant, efficient solutions.
            </p>

            <p>Here are a few key technologies I work with:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
